
.section-visual {
    max-width: 1920px;
    margin: 0 auto;
    position: relative;
    .container {
        height: 100%;
        &--image {
            display: flex;
            align-items: flex-end;
        }
    }
    .tit {
        text-transform: uppercase;
    }
    &__text {
        margin-top: 300px;
        display: flex;
        align-items: flex-end;
        aspect-ratio: 816/125;
        width: 100%;
        max-width: 816px;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            left: -100vw;
            top: 0;
            width: 100vw;
            height: 100%;
            background-color: inherit;
        }
    }
    .tit--center {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .v-breadcrumbs {
        color: #333;
    }
    &__sheet {
        height: 170px;
    }
}

.section-visual--wide {
    .v-sheet {
        min-width: 460px;
    }
}
@media (min-width: 768px) {
    .section-visual {
        &__sheet {
            height: 254px;
        }
    }
    .section-visual--wide {
        .v-sheet {
            min-width: 628px;
        }
    }
}
