
.tricky-brand-name {
    > ::v-deep(table) {
        > caption {
            display: none;
        }
        > tbody {
            > tr {
                border: none !important;
                > td {
                    display: inline-block;
                    padding: 0 !important;
                    line-height: 2;
                    color: #44474a !important;
                    word-spacing: 2px;
                    position: relative;
                    &:first-child {
                        display: none;
                    }

                    &::after {
                        content: "";
                        position: absolute;
                        bottom: 4px;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 100%;
                        height: 1px;
                        color: inherit;
                        background-color: currentColor;
                    }
                }
                &:not(:first-of-type) {
                    display: none;
                }
            }
        }
    }
}
