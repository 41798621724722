
.scroll-fixed-wrap {
    --tab-height: 60px;
    height: var(--tab-height);
    transition: 0.25s ease-out;
    .v-tabs {
        display: flex;
        height: var(--tab-height);
        .v-tab {
            width: 25% !important;
            max-width: none;
            height: 100%;
        }
    }
    .v-tab {
        border-bottom: 1px solid var(--v-grey-darken3);
        &--active {
            color: var(--v-grey-darken3);
            border-top: 1px solid var(--v-grey-darken3);
            border-left: 1px solid var(--v-grey-darken3);
            border-right: 1px solid var(--v-grey-darken3);
            border-bottom: none;
        }
    }
}
.scroll-fixed {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    // &::before,
    // &::after {
    //     content: "";
    //     position: absolute;
    //     left: 0;
    //     display: block;
    //     width: 100%;
    //     height: 1px;
    //     background-color: var(--border-color);
    // }
    // &::before {
    //     bottom: 0;
    // }
    // &::after {
    //     display: none;
    //     top: 0;
    // }
    &.active {
        position: fixed;
        top: 50px;
        left: 0;
        width: 100%;
        z-index: 10;
    }
    &:not(.active) {
        .container {
            // max-width: var(--container);
            max-width: 1330px;
            padding: 0;
        }
    }

    ::-webkit-scrollbar {
        width: 12px; /* 스크롤바의 너비 */
    }
    ::-webkit-scrollbar-thumb {
        height: 30%; /* 스크롤바의 길이 */
        background: var(--v-grey-lighten2); /* 스크롤바의 색상 */
        border-radius: 9999px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
    ::-webkit-scrollbar-track {
        background: var(--v-grey-lighten4); /*스크롤바 뒷 배경 색상*/
        border-radius: 9999px;
        border: 3px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
    }
}
.scrollactive-nav {
    position: relative;
    margin-top: auto;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .scroll-fixed-wrap {
    }
    .scroll-fixed {
        &::after {
            display: block;
        }
        &.active {
            top: 0;
            min-height: 114px;
        }
    }
}
@media (min-width: 1200px) {
}
@media (max-width: 576px) {
    .scroll-fixed {
        .container {
            padding: 0;
        }
    }
}
