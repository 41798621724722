
.swiper-pagination {
    position: static;
    margin: 8px 0;
    display: flex;
    justify-content: center;
    ::v-deep {
        .swiper-pagination-bullet {
            background-color: rgba(0, 0, 0, 0.2) !important;

            &-active {
                background-color: #007aff !important;
            }
        }
    }
}
