
::v-deep {
    #contents {
        padding-top: 0;
    }
}

::v-deep(.product-sub) {
    > table {
        margin-top: 24px;
        > tbody {
            > tr {
                border-bottom: 0 !important;
                > td {
                    padding: 0 !important;
                    display: inline-block;
                    font-size: var(--txt-font-size-sm) !important;
                    &:first-child {
                        &::after {
                            content: ":";
                            vertical-align: top;
                            padding-right: 4px;
                        }
                    }
                    &:last-child {
                        font-weight: 300;
                        word-break: keep-all;
                    }
                }
            }
        }
    }
}
@media (min-width: 768px) {
    // ::v-deep(.product-sub) {

    // }
}
