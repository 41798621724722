
.download-image {
    position: relative;
    .v-image {
        position: absolute;
        top: 0;
        left: -40px;
    }
}
@media (min-width: 768px) {
    .download-image {
        .v-image {
            left: -76%;
        }
    }
}
