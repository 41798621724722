
.v-btn-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    > .v-btn {
        margin: 0 var(--grid-gutter);
        padding: var(--btn-padding-xxl);
        border-left-width: thin !important;
        background-color: #fff !important;

        &--active {
            background-color: var(--v-primary-base) !important;
            color: #fff;
            &::before {
                opacity: 0 !important;
            }
        }
    }
}
@media (min-width: 768px) {
    .v-btn-toggle {
        margin-bottom: 60px;
    }
}
